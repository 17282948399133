import flatten from "lodash/flatten"
import {
  getJobFamilyUrl,
  getCareerBandsComparisonUrl,
  getBandDetailsUrl,
} from "@helpers/url"
import JOB_FAMILY_GROUPS from "@content/job-families.js"
import CAREER_BANDS from "@content/career-bands.js"

const careerBands = CAREER_BANDS.map(cb => {
  return {
    name: cb.bandName,
    slug: cb.bandSlug,
    category: "Career Band",
    to: getCareerBandsComparisonUrl(cb.bandSlug),
    mobileTo: getBandDetailsUrl(cb.bandSlug, `${cb.bandName[0]}1`),
  }
}).reverse()

const checkForNoJobLevels = jobFamily => {
  if (jobFamily.jobLevels?.length && jobFamily.jobLevels[0].levels?.length) {
    return false
  }
  return true
}

const jobFamilies = flatten(
  JOB_FAMILY_GROUPS.map(g => {
    return g.jobFamilies.map(jf => ({
      name: jf.name,
      groupName: g.jobFamilyGroupName,
      category: "Job Family",
      levelString: checkForNoJobLevels(g)
        ? "N/A"
        : g?.jobLevels.map(l => l.levelText).join(", "),
      to: getJobFamilyUrl(g.slug, jf.slug),
    }))
  })
)

export default [...careerBands, ...jobFamilies]
