export default [
  {
    slug: "account-management-and-sales",
    jobFamilyGroupName: "Account Management and Sales",
    jobFamilies: [
      {
        name: "Account Management",
        description: [
          "Builds and maintains effective long-term relationships and a high level of satisfaction with key senior-level decision makers and influencers at an assigned group of customer accounts that may include major strategic customers within a geographic or industry focus",
          "Identifies, develops and typically closes new sales opportunities",
          "Creates demand for the organization's products and services by raising their profile with customers",
          "Achieves revenue targets by increasing revenue spend per account",
          "May conduct regular status and strategy meetings with the customer's senior management to understand their needs and link them to the organization's product/service strategies.",
        ],
        colour: "#E6F1F7",
        slug: "account-management",
      },
      {
        name: "Client Servicing - Account Management",
        description: [
          "Manages ongoing contractual relationships and the operational delivery of services to clients for an account or group of accounts",
          "Serves as the primary point of contact with clients and ensures client satisfaction.",
        ],
        colour: "#E6F1F7",
        slug: "client-servicing-account-management",
      },
      {
        name: "New Sales",
        description: [
          "Promotes and sells products and/or services and solutions to new customers at all levels",
          "Prospects for new accounts, secures appointments, determines needs, delivers sales presentations, negotiates terms and conditions, overcomes objections and closes sales",
          "Develops strategies focused on expanding client base within defined market segments",
          "Produces new account revenue in line with current organization and individual targets/quotas.",
        ],
        colour: "#E6F1F7",
        slug: "new-sales",
      },
      {
        name: "Sales Operations, Support and Administration",
        description: [
          "Supports sales representatives and channel partners in administrative areas such as order processing, customer quotes, sales information management, product training and financing",
          "Provides operational support to sales management",
          "Plans and coordinates projects to improve the operational efficiency and effectiveness of the sales force",
          "Develops processes and procedures to support the sales process and participates in the development of requirements for sales support systems",
          "Maintains information of sales activities that support sales performance metrics and sales process improvement.",
        ],
        colour: "#E6F1F7",
        slug: "sales-operations-support-and-administration",
      },
    ],
  },
  {
    slug: "actuarial",
    jobFamilyGroupName: "Actuarial",
    jobFamilies: [
      {
        name: "Corporate Actuarial",
        description: [
          "Calculates actuarial and financial items such as unpaid claims reserves and ACA-related assets and liabilities",
          "Creates revenue and expense projections and conducts variance analyses",
          "Involves data analysis, rate methodology development, and rate filings",
          "Conducts complex trend analysis and projections to support financial forecasting and rating decisions ultimately contributing to the organization's financial health and strategic planning.",
        ],
        colour: "#E6F1F7",
        slug: "corporate-actuarial",
      },
      {
        name: "Pricing",
        description: [
          "Evaluates and determines prices for the organization's products",
          "Collects and analyzes historical data, models financial outcomes under various rating scenarios, contributes to the updating of rating methodologies and factors, and supports rate filings.",
        ],
        colour: "#E6F1F7",
        slug: "pricing",
      },
    ],
  },
  {
    slug: "business-intelligence",
    jobFamilyGroupName: "Business Intelligence",
    jobFamilies: [
      {
        name: "Data Governance",
        description: [
          "Develops, implements and administers policies and procedures to ensure the quality and protection of data within the organization, in compliance with strategic priorities along with regulatory requirements, to minimize risk to the organization",
          "Works with leadership to help define specific roles and responsibilities related to the organization's data assets",
          "Develops and maintains rules and policies regarding the organization's data and access restrictions",
          "Provides input for internal governance meetings and may chair data governance meetings.",
        ],
        colour: "#E6F1F7",
        slug: "data-governance",
      },
      {
        name: "Management Data Analysis",
        description: [
          "Collects, analyzes and reports management data to support decisions on day-to-day operations, strategic planning and specific business performance issues and improvements",
          "Uses data to obtain quantifiable economic benefit to accrue market share, revenue or cost savings (internal monetization)",
          "Collates, models, interprets and analyzes data; explains variances and trends",
          "Identifies and documents enhancements to modeling techniques.",
        ],
        colour: "#E6F1F7",
        slug: "management-data-analysis",
      },
      {
        name: "Health Data Analysis",
        description: [
          "Develops and implements innovative business solutions, leveraging both existing systems and new tools to meet user requirements",
          "Collaborates closely with management and medical directors to provide expert insights into enterprise projects.",
        ],
        colour: "#E6F1F7",
        slug: "health-data-analysis",
      },
      {
        name: "Predictive Analytics/Business Intelligence",
        description: [
          "Retrieves, analyzes and summarizes business, operations, customer and/or economic data in order to develop business intelligence, optimize effectiveness and/or predict business outcomes",
          "Identifies, analyzes, and interprets trends or patterns and prepares ongoing reports and data visualizations (e.g., charts, dashboards, heat maps) in order to support business decisions",
          "Typically uses dedicated business intelligence applications and/or cloud services (e.g., Domo, Looker, PowerBI, Qlik, Tableau, SiSense), but may also leverage the reporting capabilities of existing ERP, CRM and/or database software vendors (e.g., Oracle BI, Salesforce, SAP Business Objects, Pentaho, SSRS).",
        ],
        colour: "#E6F1F7",
        slug: "predictive-analyticsbusiness-intelligence",
      },
    ],
  },
  {
    slug: "claims",
    jobFamilyGroupName: "Claims",
    jobFamilies: [
      {
        name: "Claims Appeals",
        description: [
          "Reviews claims for eligibility and explains coverage amounts and benefit plan interpretation to patients or their representatives",
          "Reviews claims for irregularities, accuracy and completeness, and obtains additional information as needed.",
        ],
        colour: "#E6F1F7",
        slug: "claims-appeals",
      },
      {
        name: "Claims Operations ",
        description: [
          "Develops claims processing programs for all lines of insurance",
          "Processes, investigates and reviews settlement of claims",
          "May develop or recommend procedures and policies to enhance and improve the processing, investigation, and settlement of claims.",
        ],
        colour: "#E6F1F7",
        slug: "claims-operations",
      },
      {
        name: "Claims Operations Support",
        description: [
          "Determines whether to return, deny, or pay claims following organization policies and procedures",
          "Processes and adjudicates claims.",
        ],
        colour: "#E6F1F7",
        slug: "claims-operations-support",
      },
      {
        name: "Claims Quality",
        description: [
          "Audits claims for coding accuracy, benefit payment, contract interpretation and compliance with policies and procedures",
          "Selects claims through random processes and/or other criteria.",
        ],
        colour: "#E6F1F7",
        slug: "claims-quality",
      },
    ],
  },
  {
    slug: "corporate-affairs-and-communications",
    jobFamilyGroupName: "Corporate Affairs and Communications",
    jobFamilies: [
      {
        name: "Community Affairs",
        description: [
          "Researches, develops, plans, designs, maintains and implements policies and programs that enhance the organization's relations with the community, the public, government and regulatory authorities, shareholders and employees.",
        ],
        colour: "#E6F1F7",
        slug: "community-affairs",
      },
      {
        name: "Creative Design Services",
        description: [
          "Develops and maintains graphic designs (e.g., art, color themes, photographs, web pages), audio and video to support the organization's image, identity and brands",
          "Develops and maintains the organization's graphics standards, techniques and methods",
          "Develops the organization's website design and layout.",
        ],
        colour: "#E6F1F7",
        slug: "creative-design-services",
      },
      {
        name: "Internal/Employee Communications",
        description: [
          "Develops and coordinates lines of communication within the organization among employees",
          "Implements policies and programs to increase employee awareness and knowledge of activities affecting employees.",
        ],
        colour: "#E6F1F7",
        slug: "internalemployee-communications",
      },
      {
        name: "Product Content Management",
        description: [
          "Researches and writes product/service information to provide comprehensive and easily available to existing and potential customers",
          "Manages the content development, integration, format design and release of content from writers and designers",
          "Analyzes the use of website content and design; takes steps as necessary and affordable to improve information content architecture and design.",
        ],
        colour: "#E6F1F7",
        slug: "product-content-management",
      },
    ],
  },
  {
    slug: "customer-relations",
    jobFamilyGroupName: "Customer Relations",
    jobFamilies: [
      {
        name: "Customer Care / Contact Center",
        description: [
          "Provides customer services relating to sales, sales promotions, installations and communications",
          "Ensures that good customer relations are maintained and customer claims and complaints are resolved fairly, effectively and in accordance with the consumer laws",
          "Develops organization-wide initiatives to proactively inform and educate customers",
          "Develops improvement plans in response to customer surveys.",
        ],
        colour: "#E6F1F7",
        slug: "customer-care-contact-center",
      },
      {
        name: "Customer Experience Management",
        description: [
          "Manages the customer journey, which includes all steps customers go through when interacting with an organization",
          "Monitors all processes that come into direct contact with the customer",
          "Tracks, oversees and optimizes all customer interaction and ensures those interactions are of high caliber and high-quality",
          "Receives, assesses and evaluates customer feedback",
          "Understands, defines and refines the customer base on a continuous basis",
          "Assesses how the organization's sales, marketing and service departments work together to deliver seamless customer service.",
        ],
        colour: "#E6F1F7",
        slug: "customer-experience-management",
      },
      {
        name: "Member Enrollment",
        description: [
          "Prepares, processes and maintains Health Maintenance Organization (HMO) member enrollments",
          "Updates records by processing changes in member enrollments to accurately reflect current member coverage",
          "Prepares related reports.",
        ],
        colour: "#E6F1F7",
        slug: "member-enrollment",
      },
      {
        name: "Member Services and Billing",
        description: [
          "Provides new and existing members with the exemplary service in relation to member's network, benefit coverage, billing inquiries and complaints",
          "Investigates, analyzes, negotiates, resolves, documents and reports on billing issues and complaints against the organization",
          "Identifies solutions that address billing issues and presents appropriate resolution options to customers",
          "Negotiates and authorizes billing settlements within established limits and adjusts customer accounts",
          "Resolves member inquiries, supports and manages escalated/more complex member questions and complaints fairly and effectively to maintain member satisfaction",
          "Provides benefit information to members.",
        ],
        colour: "#E6F1F7",
        slug: "member-services-and-billing",
      },
      {
        name: "Operational Support",
        description: [
          "Provides timely responses to customer inquiries by telephone, email or website chatbox in an in- or outbound service center, consistent with service and quality standards",
          "Processes customer orders, bills and accounts, and applications for service, maintenance and termination",
          "Troubleshoots and resolves customer complaints.",
        ],
        colour: "#E6F1F7",
        slug: "operational-support",
      },
    ],
  },
  {
    slug: "enterprise-support",
    jobFamilyGroupName: "Enterprise Support",
    jobFamilies: [
      {
        name: "Administrative Assistance",
        description: [
          "Supports general business operations by providing various administrative support activities.",
        ],
        colour: "#E6F1F7",
        slug: "administrative-assistance",
      },
      {
        name: "Facilities Management",
        description: [
          "Ensures the optimal utilization of the organization's facilities",
          "Assesses and evaluates the physical space requirements of the organization and recommends plans to meet needs",
          "Ensures proper functioning of facilities through ongoing inspection and maintenance",
          "Operates, monitors and maintains utilities, including HVAC (heating, ventilation and air conditioning) setup, maintenance and balancing, WFI (water for injection), purified water and process equipment",
          "Maintains, troubleshoots and repairs facilities mechanical components and electrical equipment and systems in accordance with SOPs (standard operating procedures), internal requirements, manufacturer's specifications and safety policies",
          "Develops, maintains and secures a spare parts inventory of basic maintenance hand and power tools",
          "Recommends purchase of maintenance tools, equipment and supplies as required to streamline processes and increase efficiency",
          "Assists engineers in developing methods and procedures to control or improve facilities processes.",
        ],
        colour: "#E6F1F7",
        slug: "facilities-management",
      },
      {
        name: "Procurement and Vendor Management",
        description: [
          "Develops outsourcing policies, standards and procedures for the organization",
          "Evaluates and implements outsourcing options that align to the desired systems and services of the business, its systems and processes",
          "Develops and administers contracts with outsourcing services in coordination with the organization's procurement and legal functions",
          "Analyzes and reports on outsourcing vendor performance",
          "Manages the organization's relationship with outsourcing vendors and ensures that the outsourcing vendor's performance meets the organization's requirements.",
        ],
        colour: "#E6F1F7",
        slug: "procurement-and-vendor-management",
      },
      {
        name: "Risk Management",
        description: [
          "Develops, recommends and implements controls and cost-effective approaches to minimize the organization's risks",
          "Identifies and analyzes potential sources of loss to minimize risk and estimates the potential financial consequences of a loss.",
        ],
        colour: "#E6F1F7",
        slug: "risk-management",
      },
      {
        name: "Business Continuation",
        description: [
          "Develops and implements plans and practices to achieve efficient and effective communication and restoration of operations during emergencies",
          "Conducts assessments to identify gaps in business continuity, emergency and disaster recovery plans",
          "Develops and tests infrastructure protection strategies and incident response exercises",
          "Coordinates disaster recovery initiatives and plans with staff and line functions",
          "Develops and coordinates prevention and emergency preparation plans with government safety and security agencies (e.g., police, fire, military).",
        ],
        colour: "#E6F1F7",
        slug: "business-continuation",
      },
    ],
  },
  {
    slug: "external-affairs-and-community-engagement",
    jobFamilyGroupName: "External Affairs and Community Engagement",
    jobFamilies: [
      {
        name: "Government Relations",
        description: [
          "Develops and maintains policies and programs to ensure organizational awareness of government legislation, regulatory issues that affect the organization and respond to same, and develops/implements community engagement initiatives and activities",
          "Ensures that the organization's interests are represented and protected in legislative proceedings and in the development of market rules and procedures",
          "Prepares and organizes forums and gatherings with government officials to exchange ideas and information on business activities and potential legislation that may affect the organization",
          "Acts as a resource on regulatory matters with regard to product changes",
          "Supports action planning to maintain and/or improve community engagement.",
        ],
        colour: "#E6F1F7",
        slug: "government-relations",
      },
    ],
  },
  {
    slug: "finance-and-accounting",
    jobFamilyGroupName: "Finance and Accounting",
    jobFamilies: [
      {
        name: "Accounting",
        description: [
          "Establishes and maintains accounting policies and controls, fiscal controls, preparing financial reports and safeguarding the organization's assets.",
        ],
        colour: "#E6F1F7",
        slug: "accounting",
      },
      {
        name: "Accounts Payable/Receivable",
        description: [
          "Prepares, records, verifies, analyzes and reports accounts payable/receivable transactions",
          "Pays vendor invoices and receives and posts customer payments on a timely basis",
          "Maintains and reconciles accounts payable/receivable ledger accounts, financial statements and reports",
          "Prepares analyses and reconciliations of bill runs to detect fraud",
          "Ensures that transaction entry verification procedures are followed",
          "May prepare and deliver low-volume customer billing and respond to resulting queries.",
        ],
        colour: "#E6F1F7",
        slug: "accounts-payablereceivable",
      },
      {
        name: "Audit and Internal Controls",
        description: [
          "Develops, plans and evaluates internal audit programs for the organization's accounting and statistical records and the activities of various departments to ensure compliance with the organization's policies, procedures and standards",
          "Determines proper accountability of assets",
          "Audits accounting records of contract agreements in which the organization is involved",
          "Prepares reports for management on the results of audits, providing recommendations on improvements",
          "Interfaces with and assists outside auditors to expedite their work.",
        ],
        colour: "#E6F1F7",
        slug: "audit-and-internal-controls",
      },
      {
        name: "Financial Analysis",
        description: [
          "Performs economic research and studies of rates of return, depreciation and investments",
          "Analyzes profit-and-loss income statements and prepares reports and recommendations to management",
          "Generates forecasts and analyzes trends in sales, finance and other areas of business",
          "Researches economic progressions to assist the organization's financial planning",
          'Creates financial models of "what if" scenarios for future business planning decisions in areas such as new product development, new marketing strategies, etc.',
        ],
        colour: "#E6F1F7",
        slug: "financial-analysis",
      },
      {
        name: "Financial Reporting",
        description: [
          "Prepares and distributes periodic financial statements for users other than those directly employed by the organization",
          "Ensures all reports and disclosures comply with applicable government regulations, professional standards and organization policies",
          "Prepares consolidation journal entries, eliminates intercompany transactions and consolidates divisional and subsidiary financial accounts in a timely and accurate basis for inclusion in internal and external financial statements.",
        ],
        colour: "#E6F1F7",
        slug: "financial-reporting",
      },
      {
        name: "Financial Planning & Analysis",
        description: [
          "Conducts financial analyses",
          "Develops and prepares the organization's financial plans and budgets, interprets financial reports and tax returns, and maintains good corporate relations with the investment and banking communities",
          "Supports corporate planning by conducting analyses of competitors, operational effectiveness and capacity utilization",
          "May research, evaluate and analyze prospective mergers, acquisitions and divestitures.",
        ],
        colour: "#E6F1F7",
        slug: "financial-planning-and-analysis",
      },
      {
        name: "Payroll",
        description: [
          "Prepares, documents and disburses payroll checks, payroll taxes and employee benefit payments",
          "Evaluates current systems, and recommends and develops operating efficiency improvements",
          "Monitors and ensures proper documentation of employee benefit payments",
          "Prepares reports illustrating payroll expenditures, including such items as tax payments and benefit plan disbursements.",
        ],
        colour: "#E6F1F7",
        slug: "payroll",
      },
    ],
  },
  {
    slug: "general-management",
    jobFamilyGroupName: "General Management",
    jobFamilies: [
      {
        name: "Business Analysis",
        description: [
          "Conducts and manages outcomes of various studies that include analyzing, reviewing, forecasting, trending, and presenting information for operational and business planning",
          "Supports short and long term operational/strategic business activities by developing, enhancing and maintaining operational information and models",
          "Develops and implements effective/strategic business solutions through research and analysis of data and business processes.",
        ],
        colour: "#E6F1F7",
        slug: "business-analysis",
      },
      {
        name: "Business Process Improvement",
        description: [
          "Analyzes and measures the effectiveness of existing business processes and develops sustainable, repeatable and quantifiable business process improvements",
          "Researches best business practices within and outside the organization to establish benchmark data",
          "Collects and analyzes process data to initiate, develop and recommend business practices and procedures that focus on enhanced safety, increased productivity and reduced cost",
          "Determines how new information technologies can support reengineering business processes",
          "May specialize in one or more of the following areas: benchmarking, business process analysis and reengineering, change management and measurement, and/or process-driven systems requirements.",
        ],
        colour: "#E6F1F7",
        slug: "business-process-improvement",
      },
      {
        name: "Project and Program Management",
        description: [
          "Plans, monitors and manages internal projects from initiation through completion",
          "Leads or coordinates project planning, resourcing, staffing, supply and subcontract management, progress reporting, troubleshooting and people management",
          "Ensures project results meet requirements regarding technical quality, reliability, schedule and cost",
          "Monitors performance and recommends schedule changes, cost adjustments or resource additions.",
        ],
        colour: "#E6F1F7",
        slug: "project-and-program-management",
      },
      {
        name: "Strategic Planning and Implementation",
        description: [
          "Develops the organization's strategic plan(s) to ensure its continued successful growth and profitability",
          "Conducts environmental scans, organizational positioning analyses and other studies",
          "Coordinates input from all major business units to facilitate the integration of individual business unit plans with the corporate plan",
          "Connects strategy with business operations, drawing from disciplines such as strategy development, business analysis, process management, operations and systems analysis",
          "May conduct or facilitate periodic reviews of achievements and performance vs strategic plans",
          "May lead implementation of organization's strategy.",
        ],
        colour: "#E6F1F7",
        slug: "strategic-planning-and-implementation",
      },
    ],
  },
  {
    slug: "health-services",
    jobFamilyGroupName: "Health Services",
    jobFamilies: [
      {
        name: "Case Management",
        description: [
          "Coordinates healthcare for members across a variety of domains to include medical, social, and behavioral health services",
          "Navigates access to healthcare programs and community resources, and ensures individuals receive the right support to optimize their healthcare outcomes, particularly for members that have complex needs",
          "Performs assessments and develops plans of care based upon information obtained from assessments",
          "Provides members with disease-specific education and health coaching",
          "Provides education to others on members specific needs and collaborates with other members of the interdisciplinary team both internal and external to HMSA.",
        ],
        colour: "#E6F1F7",
        slug: "case-management",
      },
      {
        name: "Clinical Review",
        description: [
          "Conducts detailed analysis and review of precertification requests, post-service and post-payment claims, concurrent review and facility monitoring",
          "Determines appropriateness of care for all lines of business.",
        ],
        colour: "#E6F1F7",
        slug: "clinical-review",
      },
      {
        name: "Medical Care Management",
        description: [
          "Performs, administers and assists with diagnostic tests and clinical procedures to appropriate level or training and established procedures such as drawing blood and performing electrocardiogram tests (EKG)",
          "Monitors delivery of care by completing patient rounds, documenting care and identifying progress toward desired care outcomes",
          "Develops interdisciplinary care plan and other case management tools, participates in meetings, coordinates information and care requirements with other care providers and provides education to others regarding the case.",
        ],
        colour: "#E6F1F7",
        slug: "medical-care-management",
      },
      {
        name: "Pharmacists",
        description: [
          "Compounds and dispenses medications and other pharmaceuticals",
          "Prepares and maintains all necessary records on prescriptions, patient charges and inventory",
          "Provides information to the staff members on drugs and pharmaceuticals.",
        ],
        colour: "#E6F1F7",
        slug: "pharmacists",
      },
      {
        name: "Technical Training",
        description: [
          "Develops and implements training activities directed at both employee competencies and technical skills",
          "Collaborates with other functions to ensure that course materials reflect current specifications and to obtain information on new processes and equipment.",
        ],
        colour: "#E6F1F7",
        slug: "technical-training",
      },
      {
        name: "Health Services Operations",
        description: [
          "Supports clinicians with day-to-day administrative duties and aids members with non-medical access to healthcare",
          "This includes, but is not limited to, coordinating travel, paying caregivers that support members, working with providers to assure medically necessary appointments are obtained, working with members to schedule appointments to close care gaps, coordinating with members and providers to make referrals to case management services and finding providers for members based upon plans of care.",
        ],
        colour: "#E6F1F7",
        slug: "health-services-operations",
      },
    ],
  },
  {
    slug: "human-resources",
    jobFamilyGroupName: "Human Resources",
    jobFamilies: [
      {
        name: "Compensation and Benefits",
        description: [
          "Plans, designs, evaluates and administers employee compensation and benefit programs such as salaries, short- and long-term incentives, job evaluations, performance appraisals, retirement plans, and life, health and disability insurance.",
        ],
        colour: "#E6F1F7",
        slug: "compensation-and-benefits",
      },
      {
        name: "Employee Relations",
        description: [
          "Coordinates employee relations programs to ensure compliance with policies and practices",
          "Develops and implements policies and procedures, including grievance procedures and exit interviews",
          "Researches and responds to employee questions, concerns and grievances",
          "Maintains employee relations records.",
        ],
        colour: "#E6F1F7",
        slug: "employee-relations",
      },
      {
        name: "HR Generalist",
        description: [
          "Designs, implements and monitors a variety of human resource programs",
          "Anticipates and plans for long-term human resource needs and trends in partnership with business management.",
        ],
        colour: "#E6F1F7",
        slug: "hr-generalist",
      },
      {
        name: "Organizational Design and Effectiveness",
        description: [
          "Analyzes the organizational structure, determines changes to organizational responsibilities, staffing, managerial skills and the quality of work life",
          "Ensures policy/program changes affecting employees do not conflict with the organization's objectives",
          "May identify and incorporate environmental, social and governance (ESG) considerations into organizational structures",
          "Applies and integrates advanced and predictive analysis, people metrics and reporting to develop strategic and operational insights for workforce decision-making (e.g., staffing, learning and development, talent management, diversity and human resource compliance).",
        ],
        colour: "#E6F1F7",
        slug: "organizational-design-and-effectiveness",
      },
      {
        name: "HR Operations",
        description: [
          "Provides centralized human resource services spanning payroll, benefits and other transactions",
          "Ensures efficiency of service center operations, technology and transaction processes",
          "Establishes standards and procedures for handling employee questions, transactions and administration of human resource programs",
          "Coordinates services with the human resource information systems, human resource program managers and technology specialists.",
        ],
        colour: "#E6F1F7",
        slug: "hr-operations",
      },
      {
        name: "Talent Acquisition",
        description: [
          "Sources, recruits, screens, interviews and recommends external and/or internal candidates for all level jobs, including entry level, experienced professional/technical, IT, support staff and hourly, and possibly management",
          "May utilize the services of employment agencies",
          "Places employment ads in appropriate sources, including the Internet and print media",
          "Ensures the maintenance of accurate and concise records and reports concerning all phases of the recruitment process, including EEO statistics",
          "May recruit from colleges, technical schools and job fairs",
          "Processes the organization's human resource information using the most efficient and cost-effective computer systems and applications",
          "Researches, analyzes, designs and maintains information systems in support of human resource administration and projects",
          "Monitors HR information needs and designs new or modifies existing systems to meet changing requirements.",
        ],
        colour: "#E6F1F7",
        slug: "talent-acquisition",
      },
      {
        name: "Training",
        description: [
          "Develops, implements and evaluates employee development plans and programs",
          "Monitors employee development and training programs, assesses needs and results, develops new programs and modifies existing programs.",
        ],
        colour: "#E6F1F7",
        slug: "training",
      },
    ],
  },
  {
    slug: "information-technology",
    jobFamilyGroupName: "Information Technology",
    jobFamilies: [
      {
        name: "Architecture",
        description: [
          "Designs and develops IT architecture (integrated process, applications, data and technology) solutions to business problems in alignment with the enterprise architecture direction and standards",
          "Performs technical planning, architecture development and modification of specifications",
          "Develops specifications for new products/services, applications and service offerings",
          "Assesses the compatibility and integration of products/services proposed as standards in order to ensure an integrated architecture across interdependent technologies.",
        ],
        colour: "#E6F1F7",
        slug: "architecture",
      },
      {
        name: "Business Systems Analysis",
        description: [
          "Acts as a liaison between the IT development group and business units for the development and implementation of new systems and enhancement of existing systems",
          "Evaluates new applications and identifies systems requirements",
          "Evaluates new IT developments and evolving business requirements and recommends appropriate systems alternatives and/or enhancements to current systems",
          "Prepares communications and makes presentations on system enhancements and/or alternatives.",
        ],
        colour: "#E6F1F7",
        slug: "business-systems-analysis",
      },
      {
        name: "Database Administration",
        description: [
          "Acts as a liaison between the IT development group and business units for the development and implementation of new systems and enhancement of existing systems",
          "Evaluates new applications and identifies systems requirements",
          "Evaluates new IT developments and evolving business requirements and recommends appropriate systems alternatives and/or enhancements to current systems",
          "Prepares communications and makes presentations on system enhancements and/or alternatives.",
        ],
        colour: "#E6F1F7",
        slug: "database-administration",
      },
      {
        name: "Information and Cyber Security ",
        description: [
          "Designs, develops, implements and oversees the organization's information security and cyber resilience strategy",
          "Develops the organization's data security procedures and overall cyber-security framework, including the forensic tools and end-to-end process for the detection, root cause analysis and containment of cyber-attacks",
          "Evaluates and recommends hardware and software to provide the appropriate level of protection for data in order to protect the information systems from unauthorized access and use.",
        ],
        colour: "#E6F1F7",
        slug: "information-and-cyber-security",
      },
      {
        name: "IT Generalist",
        description: [
          "Designs, develops, implements and oversees information technology (IT), including hardware, software and networks",
          "Implements short- and long-term IT solutions that meet the needs of the organization",
          "Installs, configures, administers, upgrades and maintains security for IT hardware, software and network infrastructure.",
        ],
        colour: "#E6F1F7",
        slug: "it-generalist",
      },
      {
        name: "IT Help Desk Support",
        description: [
          "Evaluates, tests, monitors and maintains information systems (IS) and cyber security policies, procedures and systems",
          "Creates, implements and oversees identity management systems to meet specific security needs and complex compliance standards",
          "Ensures that IS and cyber security plans, controls, processes, standards, policies and procedures are aligned with IS standards and overall IS and cyber security",
          "Identifies security risks and exposures, determines the causes of security violations and suggests procedures to halt future incidents and improve security",
          "Develops techniques and procedures for conducting IS, cyber security and access and identity risk assessments and compliance audits, the evaluation and testing of hardware, firmware and software for possible impact on system security, and the investigation and resolution of security incidents such as intrusion, frauds, attacks or leaks.",
        ],
        colour: "#E6F1F7",
        slug: "it-help-desk-support",
      },
      {
        name: "Infrastructure and Network Control/Administration",
        description: [
          "Provides technical support to the organization's internal users of computer applications and hardware (e.g., PCs, servers, mainframes)",
          "Answers questions regarding system procedures, online transactions, systems status and downtime procedures and is typically located within a call center",
          "Collaborates with network services, software systems engineering and/or application development in order to restore service and/or identify problems",
          "Maintains a troubleshooting tracking log ensuring timely resolution of problems.",
        ],
        colour: "#E6F1F7",
        slug: "infrastructure-and-network-controladministration",
      },
      {
        name: "IT Systems Engineering",
        description: [
          "Responsible for the planning and engineering of an organization's systems infrastructure and implementation and design of hardware and software",
          "Monitors the performance of systems.",
        ],
        colour: "#E6F1F7",
        slug: "it-systems-engineering",
      },
      {
        name: "Software Development",
        description: [
          "Designs, develops, tests, debugs and implements software that runs computers and technologies like smart devices, networking equipment, or machinery",
          "Determines systems software design requirements",
          "Ensures that system improvements are successfully implemented and monitored to increase efficiency",
          "Generates systems software engineering policies, standards and procedures",
          "May participate in all aspects of software development, from detailed programming to high-level system design.",
        ],
        colour: "#E6F1F7",
        slug: "software-development",
      },
      {
        name: "Software Quality Assurance, Testing and Release",
        description: [
          "Conducts a wide range of quality control tests and analyses to ensure that software meets or exceeds specified standards and end user requirements",
          'Performs quality assurance and testing in an environment that typically uses state-of-the-art software applications testing processes (i.e., testing "clean room") but may also include manual QA/Testing',
          "Drafts, revises and approves test plans and scripts to ensure alignment with standards and IT strategy",
          "Creates test data files with valid and invalid records to thoroughly test program logic and verify system flow",
          "Collaborates with users to plan user acceptance testing, alpha and beta testing",
          "Ensures that system tests are successfully completed and documented and all problems are resolved",
          "May develop, revise and approve quality assurance and testing policies, standards and procedures.",
        ],
        colour: "#E6F1F7",
        slug: "software-quality-assurance-testing-and-release",
      },
      {
        name: "Tech Project Management",
        description: [
          "Manages all aspects of a diverse IT project or multiple IT projects",
          "Utilizes expertise and leadership skills to direct staff and to resolve issues to ensure project goals and requirements are met",
          "Typically uses Scrum/Agile development techniques and tools for team collaboration, issue tracking and backlog management.",
        ],
        colour: "#E6F1F7",
        slug: "tech-project-management",
      },
    ],
  },
  {
    slug: "legal-affairs",
    jobFamilyGroupName: "Legal Affairs",
    jobFamilies: [
      {
        name: "Fraud Management",
        description: [
          "Develops, implements and monitors strategies/systems for the minimization of fraud across the portfolio of the organization's interests, activities and services",
          "Liaises with clients and external parties to monitor and resolve fraud issues",
          "Ensures prevention strategies are effectively implemented",
          "Focuses on prevention of fraudulent activity, particularly through knowledge and development of systems",
          "Keeps up-to-date with legislative and guidance changes.",
        ],
        colour: "#E6F1F7",
        slug: "fraud-management",
      },
      {
        name: "Compliance/Privacy",
        description: [
          "Responsible for ensuring compliance with governmental requirements",
          "Develops and implements compliance policies and procedures",
          "Researches compliance issues and recommends changes that assure compliance with contract obligations",
          "Maintains relationships with government agencies",
          "Coordinates site visits for regulators, coordinates implementation and compliance with corrective action plans, as needed.",
        ],
        colour: "#E6F1F7",
        slug: "complianceprivacy",
      },
      {
        name: "Legal",
        description: [
          "Provides legal advice and services on issues concerning the rights, obligations and privileges of the organization",
          "Represents the organization to customers, suppliers, competitors and government agencies",
          "Provides resolution of business or technical issues by identifying legal solutions and recommending a course of action",
          "Examines legal data to determine advisability of defending or prosecuting lawsuits.",
        ],
        colour: "#E6F1F7",
        slug: "legal",
      },
      {
        name: "Legal Support",
        description: [
          "Performs or supports a variety of law-related activities that do not require a law degree, including legal or factual research, contract administration, document preparation and analysis, citation checking and trial preparation.",
        ],
        colour: "#E6F1F7",
        slug: "legal-support",
      },
      {
        name: "Regulatory Compliance",
        description: [
          "Maintains the organization's ongoing relationships with regulatory commissions/authorities",
          "Coordinates and conducts the assessment of internal controls to ensure compliance as required by regulatory commissions/authorities",
          "Develops programs and processes to manage complaint cases brought to regulatory authorities and develops process improvements to avoid future complaints",
          "Advances organization positions with internal and external parties",
          "Prepares and sponsors testimony to governmental or regulatory agencies.",
        ],
        colour: "#E6F1F7",
        slug: "regulatory-compliance",
      },
    ],
  },
  {
    slug: "marketing-and-product-development",
    jobFamilyGroupName: "Marketing and Product Development",
    jobFamilies: [
      {
        name: "Market Research/Intelligence",
        description: [
          "Performs analyses and prepares forecasts and recommendations in the areas of product preferences, sales coverage, market penetration, market practices and sales trends",
          "Researches market conditions to determine potential sales of a product.",
        ],
        colour: "#E6F1F7",
        slug: "market-researchintelligence",
      },
      {
        name: "Marketing",
        description: [
          "Designs, develops and implements marketing programs and/or pricing strategies to support the organization's products, services or market sector",
          "Uses specific marketing strategies and media (e.g., print, broadcast, digital) to launch and position products and services in a sector",
          "Identifies and implements marketing strategies and programs in collaboration with sales and technical teams.",
        ],
        colour: "#E6F1F7",
        slug: "marketing",
      },
      {
        name: "Product Development",
        description: [
          "Discovers, develops and evaluates new product ideas, enhancements to existing products or strategic product extensions, and translates research discoveries into usable and marketable products",
          "Leads, plans and tracks all phases of the product life cycle, from inception to introduction into the marketplace",
          "Develops design briefs for new product ideas, including specifications, sketches and/or models to present a clear, focused concept for strategic consideration.",
        ],
        colour: "#E6F1F7",
        slug: "product-development",
      },
    ],
  },
  {
    slug: "medical-services",
    jobFamilyGroupName: "Medical Services",
    jobFamilies: [
      {
        name: "Nurses, Physicians and Medical Assistants",
        description: [
          "Collaborates with healthcare professionals to develop individualized care plans for patients, emphasizing adherence and improved outcomes",
          "Leverages clinical skills to assess patient needs and track progress effectively",
          "Required qualifications include a relevant degree/certification and experience in healthcare support services",
          "Positions in this family require a current, unrestricted nursing license (i.e LPN, RN), physician or medical assist as indicated in the job description and/or job title, in the applicable state.",
        ],
        colour: "#E6F1F7",
        slug: "nurses-physicians-and-medical-assist",
      },
    ],
  },
  {
    slug: "provider-operations",
    jobFamilyGroupName: "Provider Operations",
    jobFamilies: [
      {
        name: "Provider Services, Business Relations and Credentialing",
        description: [
          "Manages the day-to-day activities of the provider relations function",
          "Areas of responsibility include some or all of the following: health care provider network development, contracting, credentialing, provider relations and provider services",
          "May monitor network performance, manage provider network communications, support provider orientation and education, and assist in resolution of provider issues",
          "Conducts the verification process for all contracted physicians, practitioners and nurses including an initial review of verifications",
          "Ensures practices and procedures are accurate and up-to-date with industry trends and current credentialing standards",
          "May complete state medical license applications for physicians, and perform follow-up with state medical licensure boards on status of license applications",
          "Initiates the process for ensuring malpractice coverage for all contracted providers.",
        ],
        colour: "#E6F1F7",
        slug: "provider-services-business-relations-and-credentialing",
      },
      {
        name: "Strategic Network Relations",
        description: [
          "Serves as the primary point of contact for provider organizations and their affiliated primary care providers to facilitate and manage collaborative efforts with value-based payment programs",
          "Aligns contractual agreements, fosters strategic relationships, educates providers to enhance the quality, accessibility, and sustainability of healthcare delivery under the organization's programs.",
        ],
        colour: "#E6F1F7",
        slug: "strategic-network-relations",
      },
    ],
  },
  {
    slug: "underwriting",
    jobFamilyGroupName: "Underwriting",
    jobFamilies: [
      {
        name: "Underwriting",
        description: [
          "Applies standard rates and calculates premiums on new business, renewals and endorsements related to the Underwriting unit",
          "Assesses risk and pricing to ensure organization value and ensures underwriting policies, procedures and guidelines are in compliance with the organization's expectations",
          "Disability includes Permanent Health and Critical Insurance risks.",
        ],
        colour: "#E6F1F7",
        slug: "underwriting",
      },
    ],
  },
]
