const theme = {
  colors: {
    // HMSA colors
    primary: {
      blueDark: "#0063A1",
      blue: "#006EB3",
      blueLight: "#E6F1F7",
    },
    secondary: {
      citron: "#F3EA65",
      gold: "#FFCC52",
      coral: "#EA875F",
      pewter: "#82ABC1",
      avocado: "#B2BE34",
      eggplant: "#8382A8",
      green: "#5EA756",
    },
    grey: {
      white: "#FFFFFF",
      greyBackground: "#F0F0F0",
      grey: "#D2D2D2",
      darkGray: "#363636",
      lightGray: "#EFEFEF",
      text: "#636466",
      textDark: "#3F3F3F",
      black: "#3F3F3F",
    },
  },
}

export default theme
