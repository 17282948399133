import React from "react"
import styled, { ThemeProvider } from "styled-components"
import Menu from "@components/Menu"
import HeaderBar from "@components/elements/HeaderBar"
import theme from "@assets/styles/theme"
import { Helmet } from "react-helmet"
import { Box } from "@material-ui/core"

const AppContainer = styled.div`
  display: flex;
  flex-direction: row;
  min-height: 100vh;

  @media (max-width: 1023px) {
    flex-direction: column;
  }
`

const AppBodyWrapper = styled.div`
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  width: 100vw;
`

const FlexGrow = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
`

const FooterBox = styled(Box)`
  display: flex;
  height: var(--desktop-footer-height);
  background-color: ${theme.colors.grey.black};
  color: ${theme.colors.grey.white};
  padding: 9px 20px 11px 20px;
  align-items: center;
  justify-content: flex-end;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  @media (max-width: 1023px) {
    text-align: center;
    justify-content: center;
  }
`

const AppLayout = ({ children, simpleHeader = false }) => {
  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <title>Career Visualization Tool</title>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link
          href="https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap"
          rel="stylesheet"
        />
      </Helmet>

      <AppContainer>
        <Menu />
        <AppBodyWrapper>
          <HeaderBar simpleHeader={simpleHeader} />
          <FlexGrow>{children}</FlexGrow>
          <FooterBox>
            © 2024 Hawaii Medical Service Association All rights reserved
          </FooterBox>
        </AppBodyWrapper>
      </AppContainer>
    </ThemeProvider>
  )
}

export default AppLayout
