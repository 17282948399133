// TODO: set theme color

const careerBands = [
  {
    bandName: "Executive",
    bandSlug: "executive",
    bandShortForm: "E",
  },
  {
    bandName: "Management",
    bandSlug: "management",
    bandShortForm: "M",
  },
  {
    bandName: "Professional",
    bandSlug: "professional",
    bandShortForm: "P",
  },
  {
    bandName: "Business Support",
    bandSlug: "business-support",
    bandShortForm: "B",
  },
]

export default careerBands
