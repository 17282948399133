import React from "react"
import styled from "styled-components"

import SearchTypeahead from "@components/modules/SearchTypeahead"
import logo from "@assets/logo.svg"
import theme from "@assets/styles/theme"
import UnstyledLink from "@components/elements/UnstyledLink"

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  z-index: 3;

  height: var(--desktop-navbar-height);

  border-bottom: solid 3px ${theme.colors.secondary.green};

  @media (max-width: 1023px) {
    display: none;
  }
`

const HeaderContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  width: min(1140px, calc(100vw - 32px));
`

const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-right: 8px;
`

const LogoImg = styled.img`
  height: var(--navbar-logo-height);
`

const NavLink = styled(UnstyledLink)`
  color: ${theme.colors.primary.blue} !important;
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  margin-left: 40px;
`

const SearchWrapper = styled.div`
  width: 400px;
  padding: 12px 0 12px 24px;
`

const HeaderBar = ({ simpleHeader }) => {
  return (
    <Header style={{ position: "sticky", top: "0" }}>
      <HeaderContent>
        <HeaderLeft>
          <UnstyledLink to="https://experience100.ehr.com/hmsa/Home">
            <LogoImg src={logo} />
          </UnstyledLink>
          {!simpleHeader && (
            <>
              <NavLink to="/">Career Navigator Home</NavLink>
              <NavLink to="/career-bands">Career Bands & Levels</NavLink>
            </>
          )}
        </HeaderLeft>
        {!simpleHeader && (
          <>
            <SearchWrapper>
              <SearchTypeahead small={true} style={{ width: "100%" }} />
            </SearchWrapper>
          </>
        )}
      </HeaderContent>
    </Header>
  )
}

export default HeaderBar
